@font-face {
  font-family: "Calibre";
  src: local("CalibreRegular"),
    url("./fonts/CalibreRegular.otf") format("truetype");
}
@font-face {
  font-family: "CalibreBold";
  src: local("CalibreBold"),
    url("./fonts/CalibreBold.otf") format("truetype");
}
@font-face {
  font-family: "CalibreMedium";
  src: local("CalibreMedium"),
    url("./fonts/CalibreMedium.otf") format("truetype");
}

.App {
	/*font-family: 'Lora';*/
	font-family: "Calibre";
	color: #061022;
}
.App-header{

}
.App-footer{

}

.header-nav{
	margin-top: 20px;
	height: 40px;
}

main{
	margin-top: 40px;
}

.logo{
	width: 40px;
	height: 40px;
}

.page-title{
	font-size: 28px;
	margin-bottom:30px;
	font-weight: normal;
	color: #061022;
	text-decoration: none;
}
.page-title.blod{
	font-family: "CalibreMedium";
}
.page-title img{
	width: 32px;
	height: 32px;
	margin-right: 12px;
	margin-top: -12px;
}
.page-title-large{
	font-size: 48px;
	font-family: "CalibreMedium";
}

@-webkit-keyframes pageFadeIn {
    0% {
        opacity: 0;
    }
    50%{
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-moz-keyframes pageFadeIn {
    0% {
        opacity: 0;
    }
    50%{
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-o-keyframes pageFadeIn {
    0% {
        opacity: 0;
    }
    50%{
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes pageFadeIn {
    0% {
        opacity: 0;
    }
    50%{
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
.page-content{
	font-size: 14px;
	line-height: 24px;
    animation: pageFadeIn .5s ease-out;
}

.page-content p{
	margin-bottom: 24px;
}
.page-content-title{
	font-size: 18px;
	font-family: "CalibreMedium";
}

.page-control-left{
	position: sticky;
	top: 95px;
	margin-top: 95px;
	margin-left: 1%;
}

.page-control-right{
	position: sticky;
	top: 95px;
	margin-top: 95px;
	/*margin-left: 7px;*/
	margin-right: 1%;
}
.media-group-content{
	width: 281px;
	height: 324px;
	position: relative;
	padding: 70px 32px;
	text-align: right;

	.media-group-logo{
		position: absolute;
		top:0;
		left:0;
		z-index: -1;
	}
	a{
		font-family: CalibreMedium;
		font-size: 20px;
		text-decoration: none;

		span.linls{

			background: -webkit-linear-gradient(#3c9ed5 , #6426e4);
			background: -o-linear-gradient(#3c9ed5, #6426e4);
			background: -moz-linear-gradient(#3c9ed5, #6426e4);
			background: linear-gradient(#3c9ed5 , #6426e4); 
			background-clip: text;
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
		}

		span.subspan{
			display: block;
			color:#5554de;
			font-family: Calibre;
			font-size: 14px;
			font-weight: 500;
			font-style: italic;
			margin-top: -2px;
		}

		&:hover,
		&:active{

			span.linls{
				-webkit-text-fill-color: #061022;
			}
			span.subspan{
				color: #061022;
			}
		}
	}
}
.group-bd{
	background-color: #88d380;
	color: #fff;
}
.group-ot{
	background-color: #64b5fc;
	color: #fff;
}
.member-names.bd-1{
	top: 40px;
	left: 10px;
}
.member-names.bd-2{
	top: 198px;
	left: 61px;
}
.member-names.bd-3{
	top: 439px;
	left: 0;
}
.member-names.bd-3 .name{
	margin-left: 30px;
}
.member-names.ot-1{
	top: 1278px;
	left: 52px;
}
.member-names.ot-1 .name{
	margin-left: 38px;
}
.member-names.ot-2{
	top: 1503px;
	left: 56px;
}
.member-names.ot-2 .name{
	margin-left: 27px;
}

.member-avatars{
	position: absolute;
	object-fit: cover;
	border-radius: 100%;
}
.member-link{
	font-size: 16px;
	font-weight: 600;
	color: #383838;
	text-decoration: none;
	background-image: url('/images/link-yw.svg');
	background-position: right 0px;
	background-repeat: no-repeat;
	padding-right: 20px;
	padding-top: 4px;
}
.member-avatars.bd-1{
	width: 124px;
	height: 124px;
	top: 59px;
	left:3px;
}
.member-avatars.bd-2{
	width: 124px;
	height: 124px;
	top: 210px;
	right:3px;
}
.member-avatars.bd-3{
	width: 124px;
	height: 124px;
	top: 340px;
	left:27px;
}
.member-avatars.ot-1{
	width: 104px;
	height: 104px;
	top: 1323px;
	left:118px;
}
.member-avatars.ot-2{
	width: 104px;
	height: 104px;
	top: 1437px;
	left:4px;
}
.member-avatars.ca-1{
	width: 114px;
	height: 114px;
	top: 662px;
	left:3px;
}
.member-avatars.ca-2{
	width: 114px;
	height: 114px;
	top: 772px;
	right:3px;
}
.member-avatars.ca-3{
	width: 114px;
	height: 114px;
	top: 882px;
	left:3px;
}
.member-avatars.ca-4{
	width: 114px;
	height: 114px;
	top: 1002px;
	right:3px;
}

.star{
	position: absolute;
	width: 100vw;
	left: 0;
	top: 196px;
	z-index: -1;

	height: 255px;
}
.bg{
	position: absolute;
	top:0;
	left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
	transition-property: left, top, transform;
	transition-duration: .8s;
}
.star.bureau .bg{
	left: -56px;
	top: 56px;
	transform: rotate(40deg);
	-moz-transform: rotate(40deg); 
}
.star.media .bg{
	left: -36px;
	top: 23px;
    // transform: rotate(-60deg);
    // -webkit-transform: rotate(-60deg);
    opacity: 0;
    // transition: opacity .5s;
}
.star.data .bg{
	left: -61px;
	top: 0px;
    transform: rotate(-130deg);
    -webkit-transform: rotate(-130deg);
}
.star.ideology .bg{
	left: -9px;
	top: 36px;
    transform: rotate(-230deg);
    -webkit-transform: rotate(-230deg);
}

.home-page-title{
	font-family: CalibreMedium;
	font-size: 24px;
	font-weight: 600;
	line-height: 1.17;
	letter-spacing: 0.92px;
	text-align: right;
	color: #061022;
	width: 290px;
	margin: -40px auto 0px;
	span{
		font-size: 20px;
	}
}
.home-page-subtitle{
	font-family: CalibreMedium;
	font-size: 18px;
	font-weight: 600;
	line-height: 1.78;
	letter-spacing: 0.54px;
	text-align: right;
	color: #383838;
	width: 290px;
	margin: 8px auto;
}

.home{
	position: absolute;
    animation: pageFadeIn .5s ease-out;

	height: 255px;
	width: 100%;
	top: 196px;
	left:0;
}
.home-title{
	position: absolute;
	color: #061022;
	font-family: "CalibreMedium";
	font-weight: 600;
	top: 50%;
	left:50%;

	font-size: 22px;
    transform: translate(-136%,-30%);
    -webkit-transform: translate(-136%,-30%);
}
.home-logo{
	position: absolute;
	top: 50%;
	left:50%;
    transform: translate(6%,-50%);
    -webkit-transform: translate(6%,-50%);
}

.menu-link{
	font-family: "CalibreMedium";
	position: absolute;
	color: #fff;
	text-shadow: 0 2px 4px rgba(6, 16, 34, 0.5);
	text-decoration: none;
	top: 50%;
	left:50%;

	font-size: 16px;
}
.menu-link:before{
	content:'';
	position: absolute;
	width: 80px;
	height: 80px;
	background: radial-gradient(circle, rgba(255,255,255,.7) 0%, rgba(255,255,255,.1) 20%, rgba(255,255,255,0) 25%);
	/*background: radial-gradient(circle, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 3%);*/
	transition: opacity .3s;
}
.menu-link:hover:before{
	opacity: 0;
}
.menu-link:after{
	opacity: 0;
	content:'';
	position: absolute;
	width: 80px;
	height: 80px;
	background: radial-gradient(circle, rgba(255,255,255,.95) 0%, rgba(255,255,255,.8) 14%, rgba(255,255,255,.1) 30%, rgba(255,255,255,0) 70%);
	transition: opacity .3s;
}
.menu-link:hover:after{
	opacity: 1;
}
.menu-link.bureau{
    transform: translate(-157px,-135px);
    -webkit-transform: translate(-157px,-135px);
}
.menu-link.bureau:before,
.menu-link.bureau:after{
	left: 15px;
	top: -31px;
}
.menu-link.media{
    transform: translate(115px,-94px);
    -webkit-transform: translate(115px,-94px);
}
.menu-link.media:before,
.menu-link.media:after{
	left: -52px;
	top: -27px;
}
.menu-link.data{
    transform: translate(85px,63px);
    -webkit-transform: translate(85px,63px);
}
.menu-link.data:before,
.menu-link.data:after{
	left: -49px;
	top: -29px;
}
.menu-link.ideology{
    transform: translate(-145px,115px);
    -webkit-transform: translate(-145px,115px);
}
.menu-link.ideology:before,
.menu-link.ideology:after{
	left: 24px;
	top: -29px;
}

.member-info{
	border-radius: 12px;
	background: rgb(136,211,129);
	background: linear-gradient(180deg, rgba(136,211,129,1) 0%, rgba(164,217,250,1) 100%);
	padding: 10px;
	margin-bottom: 30px;
}
.member-info-inner{
	background-color: #fff;
	padding: 22px;
}
.member-info .member-avatar{
	width: 200px;
	height: 200px;
	box-shadow: inset 0 0 7px 0 rgba(0, 0, 0, 0.3);
	border-radius: 100px;
	margin: 0 auto;
	position: relative;
}
.member-info .member-avatar:after{
	position: absolute;
	content:'';
	left: 0;
	top: 0;
	width: 200px;
	height: 200px;
	box-shadow: inset 0 0 7px 0 rgba(0, 0, 0, 0.3);
	border-radius: 100px;
}
.member-img{
	position: absolute;
	top: 50%;
	left: 50%;
    transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
	border-radius: 100px;
	width: calc( 100%);
	height: calc( 100%);
	object-fit: cover;
}
.member-contry{
	position: absolute;
	right:0;
	bottom:0;
	width: 44px;
	height: 44px;

}
.member-info .name{
	font-size: 20px;
	font-weight:600;
	color:#102755;
	margin-top: 20px;
}
.member-info .title{
	font-size: 18px;
	font-family: "CalibreMedium";
	margin-top: 8px;
}
.member-info.bd .title{
	color: #36ab29;
}
.member-info.ot .title{
	color: #367bb0;
}
.member-info .introduce{
	font-size: 14px;
	color: #828282;
	text-align: left;
	margin-top: 8px;
}
.member-info .member-links{
	margin-top: 24px;
}
.member-info .member-links a{
	margin: 0 8px;
}

.sponsor-title{
	font-family: CalibreMedium;
	font-size: 28px;
	color: #102755;
	margin-top: 24px;
}

.sponsor-description{
	font-family: Calibre;
	font-size: 20px;
	color: #102755;
	margin-top: 8px;
}

@media screen and ( min-width: 576px ) {

	.home-page-title{
		font-size: 26px;
		line-height: 1.23;
		letter-spacing: 1px;
		width: 406px;
		span{
			font-size: 22px;
		}
	}
	.home-page-subtitle{
		font-size: 20px;
		line-height: 1.6;
		letter-spacing: 0.6px;
		width: 406px;
	}
	.home{
		height: 339px;
		top: 238px;
	}
	.home-title{
		font-size: 24px;
	}
	.menu-link:before{
		background: radial-gradient(circle, rgba(255,255,255,.7) 0%, rgba(255,255,255,0.1) 30%, rgba(255,255,255,0) 40%);
	}
	.menu-link.bureau{
	    transform: translate(-197px,-180px);
	    -webkit-transform: translate(-197px,-180px);
	}
	.menu-link.media{
	    transform: translate(158px,-118px);
	    -webkit-transform: translate(158px,-118px);
	}
	.menu-link.data{
	    transform: translate(121px,84px);
	    -webkit-transform: translate(121px,84px);
	}
	.menu-link.ideology{
	    transform: translate(-178px,154px);
	    -webkit-transform: translate(-178px,154px);
	}
	.menu-link.bureau:before,
	.menu-link.bureau:after{
		left: 21px;
		top: -28px;
	}

	.menu-link.media:before,
	.menu-link.media:after{
		left: -60px;
		top: -30px;
	}

	.menu-link.data:before,
	.menu-link.data:after{
		left: -59px;
		top: -25px;
	}

	.menu-link.ideology:before,
	.menu-link.ideology:after{
		left: 30px;
		top: -26px;
	}
	.star{
		top: 238px;
		height:339px;
	}
	.page-control-left{
		margin-top: 160px;
		top: 160px;
	}

	.page-control-right{
		margin-top: 160px;
		top: 160px;
	}
	.boardOfDirectors{
		top: 0;
		left: 10px;
	}
	.operationTeam{
		top: 1065px;
		left: 136px;
	}
	.corporateAdvisor{
		top: 478px;
		left: 118px;
	}
	.member-names.bd-1{
		top: 48px;
		left: 0;
	}
	.member-names.bd-2{
		top: 84px;
		left: 190px;
	}
	.member-names.bd-3{
		top: 311px;
		left: 53px;
	}
	.member-names.ot-1{
		top: 989px;
		left: 0;
	}
	.member-names.ot-1 .title{
		margin-left: 18px;
	}
	.member-names.ot-1 .name{
		margin-left: 0;
	}
	.member-names.ot-2{
		top: 1231px;
		left: 135px;
	}
	.member-names.ot-2 .title{
		margin-left: 17px;
	}
	.member-names.ot-2 .name{
		margin-left: 0;
	}
	.member-avatars.bd-1{
		top: 67px;
		left:3px;
	}
	.member-avatars.bd-2{
		top: 105px;
		left:221px;
	}
	.member-avatars.bd-3{
		top: 212px;
		left:80px;
	}
	.member-avatars.ot-1{
		top: 1034px;
		left:3px;
	}
	.member-avatars.ot-2{
		top: 1139px;
		left:148px;
	}
	.member-avatars.ca-1{
		top: 538px;
		left:182px;
	}
	.member-avatars.ca-2{
		top: 632px;
		right:3px;
	}
	.member-avatars.ca-3{
		top: 693px;
		left:133px;
	}
	.member-avatars.ca-4{
		top: 787px;
		left:288px;
	}
	.page-title img{
		width: 40px;
		height: 40px;
	}
	.member-info-inner{
		padding: 30px;
	}
	.media-group-content{
		width: 421px;
		height: 487px;
		a{
			font-size: 26px;

			span.subspan{
				font-size: 14.4px;
			}
		}
	}
}

@media screen and ( min-width: 768px ) {

	.home-page-title{
		font-size: 32px;
		line-height: 1;
		letter-spacing: 1.2px;
		width: 509px;
		span{
			font-size: 28px;
		}
	}
	.home-page-subtitle{
		font-size: 24px;
		line-height: 1.33;
		letter-spacing: 0.8px;
		width: 509px;
		margin: 12px auto;
	}
	.home{
		height: 422px;
		top: 240px;
	}
	.home-title{
		font-size: 32px;
	}
	.menu-link{
		font-size: 20px;
	}
	.menu-link:before{
		background: radial-gradient(circle, rgba(255,255,255,.7) 0%, rgba(255,255,255,0.1) 38%, rgba(255,255,255,0) 50%);
	}
	.menu-link.bureau{
	    transform: translate(-250px,-225px);
	    -webkit-transform: translate(-250px,-225px);
	}
	.menu-link.media{
	    transform: translate(191px,-146px);
	    -webkit-transform: translate(191px,-146px);
	}
	.menu-link.data{
	    transform: translate(148px,111px);
	    -webkit-transform: translate(148px,111px);
	}
	.menu-link.ideology{
	    transform: translate(-223px,193px);
	    -webkit-transform: translate(-223px,193px);
	}
	.menu-link.bureau:before,
	.menu-link.bureau:after{
		left: 39px;
		top: -25px;
	}

	.menu-link.media:before,
	.menu-link.media:after{
		left: -60px;
		top: -28px;
	}

	.menu-link.data:before,
	.menu-link.data:after{
		left: -61px;
		top: -27px;
	}

	.menu-link.ideology:before,
	.menu-link.ideology:after{
		left: 48px;
		top: -23px;
	}
	.star{
		height: 422px;
		top: 240px;
	}
	.page-title{
		font-size: 36px;
	}
	.page-title-large{
		font-size: 64px;
	}
	.boardOfDirectors{
		top: 35px;
		left: 0;
	}
	.operationTeam{
		top: 843px;
		left: auto;
		right:0;
	}
	.corporateAdvisor{
		top: 879px;
		left: 0;
	}

	.member-names.bd-1{
		top: 83px;
		left: 170px;
	}
	.member-names.bd-2{
		top: 292px;
		left: 26px;
	}
	.member-names.bd-3{
		top: 320px;
		left: 324px;
	}
	.member-names.ot-1{
		top: 486px;
		left: 405px;
	}
	.member-names.ot-2{
		top: 765px;
		left: 367px;
	}
	.member-avatars.bd-1{
		width: 134px;
		height: 134px;
		top: 101px;
		left:217px;
	}
	.member-avatars.bd-2{
		width: 134px;
		height: 134px;
		top: 177px;
		left:39px;
	}
	.member-avatars.bd-3{
		width: 134px;
		height: 134px;
		top: 204px;
		left:360px;
	}
	.member-avatars.ot-1{
		top: 526px;
		left:auto;
		right:3px;
	}
	.member-avatars.ot-2{
		top: 676px;
		left:354px;
	}
	.member-avatars.ca-1{
		top: 485px;
		left:3px;
	}
	.member-avatars.ca-2{
		top: 577px;
		left:140px;
	}
	.member-avatars.ca-3{
		top: 638px;
		left:3px;
	}
	.member-avatars.ca-4{
		top: 752px;
		left:114px;
	}
	.media-group-content{
		width: 491px;
		height: 568px;
		a{
			font-size: 30px;

			span.subspan{
				font-size: 16px;
			}
		}
	}
}

@media screen and ( min-width: 992px ) {
	.logo{
		width: 60px;
		height: 60px;
	}
	.page-title{
		margin-bottom: 40px;
	}
	.page-content{
		font-size: 16px;
		line-height: 26px;
	}
	.page-content p{
		margin-bottom: 26px;
	}
	.page-content-title{
		font-size: 20px;
	}
	.boardOfDirectors{
		top: 46px;
		left: 34px;
	}
	.operationTeam{
		top: 708px;
		left: 549px;
	}
	.corporateAdvisor{
		top: 787px;
		left: 29px;
	}

	.member-names.bd-1{
		top: 83px;
		left: 270px;
	}
	.member-names.bd-2{
		top: 292px;
		left: 126px;
	}
	.member-names.bd-3{
		top: 302px;
		left: 424px;
	}
	.member-names.ot-1{
		top: 557px;
		left: auto;
		right: 0;
	}
	.member-names.ot-2{
		top: 638px;
		left: 497px;
	}
	.member-avatars.bd-1{
		top: 101px;
		left:317px;
	}
	.member-avatars.bd-2{
		top: 177px;
		left:139px;
	}
	.member-avatars.bd-3{
		top: 204px;
		left:460px;
	}
	.member-avatars.ot-1{
		top: 466px;
	}
	.member-avatars.ot-2{
		top: 542px;
		left:521px;
	}
	.member-avatars.ca-1{
		top: 477px;
		left:18px;
	}
	.member-avatars.ca-2{
		top: 529px;
		left:194px;
	}
	.member-avatars.ca-3{
		top: 637px;
		left:53px;
	}
	.member-avatars.ca-4{
		top: 710px;
		left:246px;
	}
	.page-title img{
		width: 60px;
		height: 60px;
		margin-right: 20px;
	}
	.member-info{
		margin-bottom: 40px;
	}
	.member-info-inner{
		padding: 38px;
	}
	.member-info .name{
		font-size: 22px;
	}
	.member-info .title{
		font-size: 20px;
	}
	.media-group-content{
		width: 702px;
		height: 811px;
		a{
			font-size: 44px;

			span.subspan{
				font-size: 24px;
			}
		}
	}
}

@media screen and ( min-width: 1200px ) {
	.boardOfDirectors{
		top: 58px;
		left: 36px;
	}
	.operationTeam{
		top: 703px;
		left: 650px;
	}
	.corporateAdvisor{
		top: 794px;
		left: 42px;
	}

	.member-names.bd-1{
		top: 78px;
		left: 286px;
	}
	.member-names.bd-2{
		top: 287px;
		left: 142px;
	}
	.member-names.bd-3{
		top: 297px;
		left: 440px;
	}
	.member-names.ot-2{
		top: 633px;
		left: 520px;
	}
	.member-avatars.bd-1{
		top: 101px;
		left:344px;
	}
	.member-avatars.bd-2{
		top: 177px;
		left:166px;
	}
	.member-avatars.bd-3{
		top: 204px;
		left:487px;
	}
	.member-avatars.ot-1{
		top: 466px;
		left: 688px;
	}
	.member-avatars.ot-2{
		top: 542px;
		left:548px;
	}
	.member-avatars.ca-1{
		top: 502px;
		left:3px;
	}
	.member-avatars.ca-2{
		top: 524px;
		left:193px;
	}
	.member-avatars.ca-3{
		top: 637px;
		left:80px;
	}
	.member-avatars.ca-4{
		top: 710px;
		left:273px;
	}
}